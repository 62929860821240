var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-preview"},[_c('button',{staticClass:"btn-close",on:{"click":_vm.closePreview}}),_c('div',{staticClass:"media-container"},[(_vm.isLoading)?_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"spinner-border text-primary me-3"}),_c('span',{staticClass:"text-muted"},[_vm._v("Loading media...")])]):_vm._e(),_c('div',{class:{
        invisible:
          _vm.isLoading ||
          _vm.mimeType == 'application/pdf' ||
          (_vm.mimeType || '').includes('video') ||
          _vm.mimeType == 'youtube',
      },attrs:{"id":"osd"}}),(_vm.mimeType == 'application/pdf')?_c('div',{attrs:{"id":"pdf"}},[_c('iframe',{staticClass:"w-100 h-100",attrs:{"src":_vm.url}})]):_vm._e(),(_vm.mimeType && _vm.mimeType.includes('video'))?_c('video',{staticClass:"w-100 h-100",attrs:{"id":"video","src":_vm.url,"controls":""}}):_vm._e(),(_vm.mimeType && _vm.mimeType == 'youtube')?_c('div',{attrs:{"id":"youtubeEmbed"}},[_c('iframe',{attrs:{"src":_vm.url,"allowfullscreen":""}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }